import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { URL_AXIOS } from '../URL/URL';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import PasswordStrengthBar from 'react-password-strength-bar';
import LoadingSpinner from './admins/LoadingSpinner';
import { BlockUI } from 'primereact/blockui';
function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [motpasse, setMotpasse] = useState("");
    const [confirmation, setConfirmation] = useState("");
    const [statutUser, setStatutUser] = useState(false);
    const [user_id, setUser_id] = useState(0);
    const [typePwd, setTypePwd] = useState("password");
    const [iconValue, setIconValue] = useState("pi pi-eye");
    const [typePwdc, setTypePwdc] = useState("password");
    const [iconValuec, setIconValuec] = useState("pi pi-eye");
    const [loadingspin, setLoadingspin] = useState(false);

    const seePassword = () => {
        if (typePwd === "password") {
            setTypePwd("text");
            setIconValue("pi pi-eye-slash")
        } else {
            setTypePwd("password");
            setIconValue("pi pi-eye")
        }

    }

    const seePasswordConfirme = () => {
        if (typePwdc === "password") {
            setTypePwdc("text");
            setIconValuec("pi pi-eye-slash")
        } else {
            setTypePwdc("password");
            setIconValuec("pi pi-eye")
        }

    }
    const hidePassword = () => {

    }
    const Connect = async () => {
        try {
            if (motpasse === confirmation) {
                const data = {
                    password: motpasse,
                    statut_pwd: "1"

                }
                const response = await axios.post(URL_AXIOS + `update_user_api_Login/${user_id}`, data);
                if (response.data.status === 200) {
                    setStatutUser(false);
                }
            } else {
                setConfirmation("")
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Les deux mots de passe ne correspondent pas !",
                    showConfirmButton: false,
                    timer: 1500
                });
            }

        } catch (error) {
            alert("Incorrect" + error.message)
        }
    }



    const CheckStatusUser = async (email) => {
        try {

            setEmail(email)
            const data = {
                email: email,
            }
            const response = await axios.post(URL_AXIOS + 'loginConfirme', data);
            if (response.data.status === 200) {

                if (response.data.Login) {
                    if (response.data.Login.role === "A") {
                        if (response.data.Login.statut_pwd === "0") {
                            setStatutUser(true);
                            setUser_id(response.data.Login.id);
                        } else {
                            setStatutUser(false)
                        }
                    } else {
                        setStatutUser(false)
                    }
                } else {
                    setStatutUser(false)
                }
            } else {
                setStatutUser(false)
            }
        } catch (error) {
            alert("Incorrect" + error.message)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            Login();
        }
    };

    const Login = async () => {

        try {


            setLoadingspin(true)
            if (email && motpasse) {

            } else if(email && motpasse==""){
               
            } else if(email=="" && motpasse){
               
            }
            const data = {
                email: email,
                motpasse: motpasse
            }

            const response = await axios.post(URL_AXIOS + 'Login', {
                email: email,
                motpasse: motpasse
            });

            if (response.data.status === 200) {
                setLoadingspin(false)
                if (response.data.Login === null) {
                    Swal.fire({
                        title: "Mot de passe ou nom d'utilisateur incorrect !",
                        icon: 'error',
                        width: 600,
                        padding: "3em",
                        color: "#716add",
                    });

                } else if (response.data.Login.role === "A") {

                    if (response.data.Login.statut === "0") {

                        Swal.fire({
                            title: "Erreur!",
                            text: "Votre compte utilisateur est bloqué ou n'est pas encore actif. Veuillez contacter l'administrateur",
                            icon: "error"
                        });
                    } else {
                        navigate('/sites');
                        sessionStorage.setItem("nom", response.data.Login.name);
                        sessionStorage.setItem("user_id", response.data.Login.id);
                        sessionStorage.setItem("site_id", response.data.Login.site_id);
                        sessionStorage.setItem("societe", response.data.Login.societe);
                        sessionStorage.setItem("type", response.data.Login.type);
                        sessionStorage.setItem("membre_id", response.data.membre.id);
                    }

                } else { }

            } else {
                Swal.fire({
                    title: "Mot de passe ou nom d'utilisateur incorrect !",
                    icon: 'error',
                    width: 600,
                    padding: "3em",
                    color: "#716add",
                });
                setLoadingspin(false)
            }
        } catch (error) {
            console.log("Erreur" + error.message);
            setLoadingspin(false)
        }
    }
    return (

        <div id="login-page" style={{ backgroundImage: 'url(../../img/login-bg2.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh' }}>
            {loadingspin && <LoadingSpinner />}
            <BlockUI blocked={loadingspin} fullScreen />
            <div class="container">
                <form class="form-login">
                    <h2 class="form-login-heading" style={{ fontWeight: 'bold', backgroundColor: "#223a66" }}>TER<span style={{ color: "#d9534f" }}>AT</span>EX</h2>
                    {statutUser && <h5 style={{ textAlign: 'center', marginTop: 20, color: 'red', textTransform: "uppercase" }}>
                        Creer ou confirmer  votre mot de passe
                    </h5>}
                    {/* {statutUser && <h5 style={{ textAlign: 'center', marginTop: 10, color: 'red' }}>
                        
                    </h5>} */}
                    <div class="login-wrap">

                        <input type="text"
                            value={email}
                            onChange={(e) => CheckStatusUser(e.target.value)}
                            // onKeyUp={(e)=>CheckStatusUser(e.target.value)}
                            class="form-control"
                            placeholder="Saisissez votre mail"
                            autofocus />
                        <br />
                        {!statutUser &&

                            // <input
                            //     value={motpasse}
                            //     onChange={(e) => setMotpasse(e.target.value)}
                            //     type="password"
                            //     class="form-control"
                            //     placeholder="Saisissez votre mot de passe" />

                            <IconField iconPosition="right">
                                <InputIcon className={iconValue} onClick={() => seePassword()}> </InputIcon>
                                <InputText v-model="value1"
                                    type={typePwd}
                                    value={motpasse}
                                    onChange={(e) => setMotpasse(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Saisissez votre mot de passe" className='form-control' />
                            </IconField>
                        }
                        {statutUser && <div class="login-wrap2">
                            <IconField iconPosition="right">
                                <InputIcon className={iconValue} onClick={() => seePassword()}> </InputIcon>
                                <InputText v-model="value1"
                                    type={typePwd}
                                    value={motpasse}
                                    onChange={(e) => setMotpasse(e.target.value)}
                                    placeholder="Nouveau mot de passe" className='form-control' />
                            </IconField>
                            {(motpasse && !confirmation) && <PasswordStrengthBar password={motpasse} shortScoreWord={"très court"} scoreWords={["faible", "faible", "moyen", "bon", "fort"]} style={{ marginTop: 10 }} />}


                            <br />

                            <IconField iconPosition="right">
                                <InputIcon className={iconValuec} onClick={() => seePasswordConfirme()}> </InputIcon>
                                <InputText v-model="value1"
                                    type={typePwdc}
                                    value={confirmation}
                                    onChange={(e) => setConfirmation(e.target.value)}
                                    placeholder="Confirmation" className='form-control' />
                            </IconField>
                            {confirmation && <PasswordStrengthBar password={confirmation} shortScoreWord={"très court"} scoreWords={["faible", "faible", "moyen", "bon", "fort"]} style={{ marginTop: 10 }} />}

                            {/* <input
                            value={confirmation}
                            onChange={(e) => setConfirmation(e.target.value)}
                            type="password"
                            class="form-control"
                            placeholder="Confirmation" /> */}
                            <br />
                        </div>

                        }
                        {!statutUser && <div class="checkbox" style={{ display: "flex", justifyContent: "space-between" }}>
                            <input type="checkbox" value="remember-me" style={{ marginLeft: "0%" }} />
                            <p style={{ marginLeft: "5%" }}>Se souvenir de moi</p>
                            <span class="pull-right">
                                <a href="https://e-metech.com/forgot-password" style={{ color: "#337ac7" }}> Forgot Password?</a>
                            </span>
                        </div>}

                        <button class="btn btn-theme btn-block"
                            style={{ backgroundColor: "#223a66" }}
                            type="button"
                            onClick={() => statutUser ? Connect() : Login()}
                        ><i class="fa fa-lock"></i>&nbsp;&nbsp;{statutUser ? "CONFIRMER" : "CONNEXION"}</button>
                        <hr />
                        <a className='btn btn-danger form-control mt-[20px]' style={{ color: "white" }} href="https://e-metech.com/"><i className='fa fa-home'></i>Accueil</a>
                        <div class="registration" style={{ marginTop: 20 }}>
                            Vous n'avez pas encore de compte ?<br />
                            <button type='button' onClick={() => navigate('/create-compte')} style={{ color: "#337ac7", border: "none" }}>
                                Créer un compte
                            </button>
                        </div>
                    </div>



                    <div aria-hidden="true" aria-labelledby="myModalLabel" role="dialog" tabindex="-1" id="myModal" class="modal fade">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                    <h4 class="modal-title">Forgot Password ?</h4>
                                </div>
                                <div class="modal-body">
                                    <p>Enter your e-mail address below to reset your password.</p>
                                    <input type="text" name="email" placeholder="Email" autocomplete="off" class="form-control placeholder-no-fix" />
                                </div>
                                <div class="modal-footer">
                                    <button data-dismiss="modal" class="btn btn-default" type="button">Cancel</button>
                                    <button class="btn btn-theme" type="button">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default Login;