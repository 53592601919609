import React, { useEffect } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { URL_AXIOS } from '../../URL/URL';
import { useContext } from 'react';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from "primereact/autocomplete";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FaFileExcel } from "react-icons/fa6";
import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';
function Modalites() {

    const navigate = useNavigate();
    const { modalite, setModalite } = useContext(ThemeContext);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 25;
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = modalite.slice(firstIndex, lastIndex);
    const npage = Math.ceil(modalite.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const toast = useRef(null);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [visible, setVisible] = useState(false);
    const [libelle_modalite, setLibelleModalite] = useState('');
    const [id, setId] = useState(0);
    const [boolSave, setBoolSave] = useState(false);
    const [code, setCode] = useState('');

    const [loading, setLoading] = useState(true);

    const liste_modalite = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'list_modalite');
            if (response.data.status === 200) {
                setModalite(response.data.modalites);
                setLoading(false);
            }
        } catch (Error) {

        }
    }

    const add_modalite = async () => {
        try {
            const data = {
                libelle_modalite: libelle_modalite,
                code: code
            }
            const response = await axios.post(URL_AXIOS + 'addModalite', data);
            if (response.data.status === 200) {
                Swal.fire({
                    title: "Réussi",
                    text: "La modalité a été enregistrée avec succès !",
                    icon: "success"
                });
                liste_modalite()
            }
        } catch (Error) {

        }

    }

    const update_modalite = async (id) => {
        try {
            const data = {
                libelle_modalite: libelle_modalite,
                code: code
            }
            const response = await axios.post(URL_AXIOS + `updateModalite/${id}`, data);
            if (response.data.status === 200) {
                liste_modalite();
            }
        } catch (Error) {

        }

    }

    const delete_modalite = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `deleteModalite/${id}`);
            if (response.data.status === 200) {
                liste_modalite()
            }
        } catch (Error) {

        }

    }

    const Deleteconfirm2 = (id_m) => {
        Swal.fire({
            title: "Question ?",
            text: "Voulez-vous supprimer cette modalité ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'Non',
            confirmButtonText: "Oui"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Réussi !",
                    text: "La suppression de la modalité a été effectuée avec succès !",
                    icon: "success"
                });

                delete_modalite(id_m);
                liste_modalite();
            }
        });

    };

    const Addconfirm2 = () => {
        setVisible(false)
        Swal.fire({
            title: "Question ?",
            text: "Voulez-vous enregistrer cette modalité ?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'Non',
            confirmButtonText: "Oui"
        }).then((result) => {
            if (result.isConfirmed) {

                setBoolSave(false)
              

                add_modalite();
                // liste_modalite();

            }
        });

    };

    const updateconfirm2 = () => {
        setVisible(false)
        Swal.fire({
            title: "Question ?",
            text: "Voulez-vous modifier cette modalité ?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'Non',
            confirmButtonText: "Oui"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Réussi !",
                    text: "La modalité a été modifiée avec succès !",
                    icon: "success"
                });

                update_modalite(id);
                // liste_modalite();
            }
        });

    };
    const select_modaliteById = async (id) => {
        try {

            const response = await axios.get(URL_AXIOS + `selectModaliteById/${id}`);
            if (response.status === 200) {
                setLibelleModalite(response.data.libelle_modalite);
                setCode(response.data.code);
                setId(response.data.id);
                setVisible(true);
                setBoolSave(true);
            }
        } catch (Error) {

        }

    }
    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(modalite);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Liste_gains');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }

    const addModalite = () => {
        setVisible(true);
        setBoolSave(false)
        setLibelleModalite("");
        setCode("");
    }

    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                <button type='button' onClick={() => select_modaliteById(data.id)} className='btn btn-warning'  >
                    <i className='fa fa-edit'></i>
                </button>
                <button type='button' style={{ marginLeft: 10 }} onClick={() => Deleteconfirm2(data.id)} className='btn btn-danger'>
                    <i className='fa fa-trash-o'></i>
                </button>
            </div>
        )
    }

    useEffect(() => {
        //liste_gains();
        liste_modalite();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);
    return (
        <section id="container">
            <Dialog header="Modalite" headerClassName='bg-[#4ECDC4] text-white' visible={visible} style={{ width: '40vw' }} onHide={() => setVisible(false)}>
                <div style={{ width: "100%", display: "flex", marginTop: 20, flexDirection: "row" }}>

                    <input type='text' className='form-control' id="code" placeholder='code' style={{ width: "20%", fontSize: 15, fontWeight: 500 }} value={code} onChange={(e) => setCode(e.target.value)} />
                    <input type='text' className='form-control' id="username" placeholder='modalité' style={{ width: "80%", fontSize: 15, fontWeight: 500, marginLeft: 10 }} value={libelle_modalite} onChange={(e) => setLibelleModalite(e.target.value)} />

                </div>
                <button type='button' style={{ marginTop: 10 }} onClick={boolSave === false ? () => Addconfirm2() : () => updateconfirm2()} className={boolSave === false ? 'btn btn-info form-control' : 'btn btn-warning form-control'}>{boolSave === false ? "Enregistrer" : "Modifier"}</button>
            </Dialog>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Header />
            <AsideBar username={usernameconnected} />
           {loading ? <Loading /> :  <section id="main-content">
                <section class="wrapper">
                    <div className='row ' style={{ padding: 10, marginTop: 20 }}>
                        <div className="col col-md-8 flex">

                            <Button className='btn btn-info' icon="pi pi-plus" label='Nouvelle modalité'
                                onClick={() => addModalite()}
                            />
                            <button type="button" className='btn btn-success w-[180px] flex justify-between items-center' title='Exporter les données'
                                onClick={exportExcel}
                                style={{ marginLeft: 10, color: 'white' }}
                            >
                                <FaFileExcel />
                                <span style={{ marginLeft: 10 }}>Exporter les données</span>
                            </button>
                        </div>
                    </div>
                    <div class="row " style={{ padding: 10 }}>
                        <DataTable value={modalite} emptyMessage="Aucune modalité disponible!" paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className="custom-datatable">
                            <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center' }} headerStyle={{ marginLeft: "100px" }} field='id' style={{ width: '5%', height: '40px' }}></Column>
                            <Column field={'code'} header="CODE" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field={'libelle_modalite'} header="LIBELLE" style={{ width: 'auto', height: '40px' }}></Column>
                            {/* <Column field='pays' header="PAYS" style={{ width: 'auto', height: '40px' }}></Column> */}
                            <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px' }}></Column>
                        </DataTable>
                        {/* <table class="table table-bordered table-striped table-condensed">
                            <thead>
                                <tr style={{ backgroundColor: 'white' }}>
                                    <th style={{ color: 'red', width: "3%" }}><center>#</center></th>
                                    <th style={{ color: 'red', width: "10%" }}>CODE</th>
                                    <th style={{ color: 'red' }}>LIBELLE</th>
                                    <th style={{ color: 'red', width: "15%" }}>DATE</th>
                                    <th style={{ color: 'red' }} colSpan={2}><center>ACTIONS</center></th>
                                </tr>
                            </thead>
                            <tbody>
                                {records
                                    .map((si, index) => (
                                        <tr key={si.id}>
                                            <td><center>{index + 1}</center></td>
                                            <td>{si.code}</td>
                                            <td>{si.libelle_modalite}</td>
                                            <td>{si.created_at}</td>
                                            <td style={{ width: 30 }}><center><button type='button' onClick={() => select_modaliteById(si.id)} className='btn btn-warning'  >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </center></td>
                                            <td style={{ width: 30 }}><center><button type='button' onClick={() => Deleteconfirm2(si.id)} className='btn btn-danger'>
                                                <i className='fa fa-trash-o'></i>
                                            </button>
                                            </center></td>
                                        </tr>
                                    ))}
                            </tbody>

                        </table> */}

                        {/* <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={prevPage}
                                    >Prev</a>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href='#' className='page-link'
                                                onClick={() => changeCPage(n)}
                                            >{n}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={nextPage}
                                    >Next</a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </section>
            </section>}
            <Footer />
        </section>
    );
}

export default Modalites;