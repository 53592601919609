import React, { useEffect } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { useContext } from 'react';
import Footer from '../Footer';
import { useRef } from 'react';
import { useState } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { ThemeContext } from '../../context/ContextHeader';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';

function Manipulateurs() {
    const navigate = useNavigate();
    const { sites, setSites, siteManipulateurs, setSiteManipulateurs } = useContext(ThemeContext);
    //const [siteManipulateurs, setSiteManipulateurs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 15;
    const dt = useRef(null);
    const toast = useRef(null);
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = siteManipulateurs.slice(firstIndex, lastIndex);
    const npage = Math.ceil(siteManipulateurs.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [visible, setVisible] = useState(false);
    const [site_id, setSite_id] = useState('');
    // const [sites, setSites] = useState([]);
    const [emailError, setEmailError] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState("");
    const [mail, setMail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [boolStatus, setBoolStatus] = useState(false);
    const [manuId, setManuId] = useState(0);
    const [message_mail, setMessage_mail] = useState("");
    const [loading, setLoading] = useState(true);

    const AddMani = () => {
        setManuId(0);
        setBoolStatus(false);
        setTelephone('');
        setMail('');
        setNom('');
        setPrenom('');
        setVisible(true);
        setSite_id('');
        setMessage_mail("");
    }
    const handleInputSite = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSite_id(value)

        } else {
            setSite_id("")
        }
    }

    const handleInputTelephone = (event) => {
        const value = event.target.value;
        const regex = /^-?\d*$/;

        if (regex.test(value)) {
            setTelephone(value);
        } else {

        }
    };

    const handleNom = (e) => {
        const value = e.target.value;
        setNom(value);
    }
    const handlePrenom = (e) => {
        const value = e.target.value;
        setPrenom(value);
    }

    const handleMail = (e) => {
        const value = e.target.value;
        if (value !== "") {
            setMail(value);

        } else {
            setMail("");
        }

    }
    const EmailValidation = (email) => {

        const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
        if (regex.test(email)) {
            return true;
        } else if (!regex.test(email) && email !== "") {
            setMessage_mail("le mail n'est pas valide");
            return false;
        } else {
            setMessage_mail("");
            return false;
        }
    };
    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status == 200) {
                setSites(response.data.sites);

            }
        } catch (Error) {

        }

    }

    const addManipulation = async () => {
        try {

            if (site_id !== "" && nom !== "") {
                const data = {
                    nom: nom.toUpperCase(),
                    prenom: prenom!=="" ? prenom :"",
                    mail: mail,
                    telephone: telephone,
                    site_id: site_id
                }

                const response = await axios.post(URL_AXIOS + 'store_manipulation', data);
                if (response.data.status === 200) {
                    liste_site_manipulateurs();
                    setNom("");
                    setPrenom("");
                    setTelephone("");
                    setVisible(false);

                }
            } else {
                setVisible(false);
                Swal.fire({
                    title: "Erreur !",
                    text: "Veuillez remplir les champs obligatoires!",
                    icon: "error"
                }).then((result) => {
                    if (result.isConfirmed) {
                        setVisible(true);
                    }
                });
            }



        } catch (error) {
            alert("erreur " + error.message)
        }
    }
    const liste_site_manipu_get_data_by = async (id_mani) => {
        try {
            const response = await axios.get(URL_AXIOS + `manipulateur_get_id/${id_mani}`);
            setNom(response.data.mani_site.nom);
            setPrenom(response.data.mani_site.prenom);
            setMail(response.data.mani_site.mail);
            setTelephone(response.data.mani_site.telephone);
            setSite_id(response.data.mani_site.site_id);
            setManuId(response.data.mani_site.id_mani_site)
            setVisible(true);
            setBoolStatus(true);
            setMessage_mail("");
        } catch (error) {
            // Handle errors more gracefully
            console.error("An error occurred:", error);
        }
    };
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }
    const confirm2 = (id) => {



        Swal.fire({
            title: "Question ?",
            text: "Voulez-vous supprimer ce manipulateur ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'Non',
            confirmButtonText: "Oui"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Réussi !",
                    text: "Le manipulateur a été supprimé avec succès !",
                    icon: "success"
                });
                delete_manipulateurs(id);
                liste_site_manipulateurs();
            }
        });
    };
    const liste_site_manipulateurs = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_manipulation');
            if (response.data.status == 200) {
                setSiteManipulateurs(response.data.vue_manipulateurs_site);
                setLoading(false)

            }
        } catch (Error) {

        }

    }
    const delete_manipulateurs = async (id_mani) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_manipulation/${id_mani}`);
            if (response.data.status == 200) {
                liste_site_manipulateurs();

            }
        } catch (Error) {

        }

    }

    const UpdateManipulation = async (id) => {
        try {
            if (nom !== "" && site_id !== "") {
                const data = {
                    nom: nom,
                    prenom: prenom,
                    mail: mail,
                    telephone: telephone,
                    site_id: site_id
                }

                const response = await axios.post(URL_AXIOS + `update_manipulation/${id}`, data);
                if (response.data.status === 200) {

                    setVisible(false);
                    setBoolStatus(false);
                    liste_site_manipulateurs();
                }

            }else{
                setVisible(false);
                Swal.fire({
                    title: "Erreur !",
                    text: "Veuillez remplir les champs obligatoires!",
                    icon: "error"
                }).then((result) => {
                    if (result.isConfirmed) {
                        setVisible(true);
                    }
                });
            
            }


        } catch (error) {
            alert("erreur " + error.message)
        }
    }

    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>
                <button type='button' onClick={() => liste_site_manipu_get_data_by(data.id_mani_site)} className='btn btn-warning '  >
                    <i className='fa fa-edit'></i>
                </button>
                <button type='button' style={{ marginLeft: 10 }} onClick={() => confirm2(data.id_mani_site)} className='btn btn-danger'>
                    <i className='fa fa-trash-o'></i>
                </button>
            </div>
        )
    }
    useEffect(() => {
        liste_site_manipulateurs();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);
    return (
        <section id="container">
            <Dialog header="Manipulateur" headerClassName='bg-[#4ECDC4] text-white' visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className='container-fluid mt-[20px]'>

                    <div className='row'>
                        <div className='col col-md-12'>
                            <input type='text' className='form-control' placeholder='Veuillez saisir le nom *'
                                value={nom}
                                onChange={handleNom}
                            />
                        </div>
                        {message_mail !== "" ? <label style={{ color: 'red' }}>{message_mail}</label> : ""}

                    </div>
                    <div className='row'>
                        <div className='col col-md-12'>
                            <input type='text' className='form-control' placeholder='Veuillez saisir le prenom'
                                value={prenom}
                                onChange={handlePrenom}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col col-md-12'>
                            <input type='text' className='form-control' placeholder='Veuillez saisir le mail'
                                value={mail}
                                onChange={(e) => handleMail(e)}
                            />

                        </div>

                    </div>
                    <div className='row'>
                        <div className='col col-md-12'>
                            <input type='text' value={telephone} onChange={handleInputTelephone} className='form-control' placeholder='Veuillez saisir le téléphone' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col col-md-12'>
                            <select className='form-control' value={site_id} onChange={handleInputSite}
                               
                            >
                                <option value="">Veuillez sélectionner un site *</option>

                                {sites.map((city) => (
                                    <option key={city.id} value={city.id}>
                                        {city.societe}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col col-md-12'>
                            <button type='button' className={boolStatus === false ? 'btn btn-info form-control' : 'btn btn-warning form-control'}
                                onClick={boolStatus === false ? () => addManipulation() : () => UpdateManipulation(manuId)}
                            >{boolStatus === false ? 'Enregistrer' : 'Mettre à jour'}</button>
                        </div>

                    </div>
                </div>
            </Dialog>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Header />
            <AsideBar username={usernameconnected} />
            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper">
                    <div className='row mt'>
                        <div className='col col-md-12'>
                            <Button className='btn btn-info' icon="pi pi-plus" onClick={() => AddMani()} label="Nouveau Manipulateur"/>
                        </div>
                    </div>
                    <div class="row " style={{ padding: 10 }}>
                        <DataTable value={siteManipulateurs} emptyMessage="Aucun manipulateur disponible!" paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className="custom-datatable">
                            <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center' }} headerStyle={{ marginLeft: "100px" }} field='id_mani_site' style={{ width: '5%', height: '40px' }}></Column>
                            <Column field={'societe'} header="CENTRE" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field={(data) => { return <div>{data.nom + " " + ((data.prenom !== "" && data.prenom !== null) ? data.prenom : "")}</div> }} header="NOM(S) ET PRENOM(S)" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field={'mail'} header="EMAIL" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column field='telephone' header="TELEPHONE" style={{ width: 'auto', height: '40px' }}></Column>
                            {/* <Column field='pays' header="PAYS" style={{ width: 'auto', height: '40px' }}></Column> */}
                            <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px' }}></Column>
                            <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px' }}></Column>
                        </DataTable>
                        {/* <table ref={dt} class="table table-bordered table-striped table-condensed">
                            <thead>
                                <tr style={{ backgroundColor: 'white' }}>
                                    <th style={{ color: 'red' }}><center>#</center></th>
                                    <th style={{ color: 'red' }}>CENTRE</th>
                                    <th style={{ color: 'red' }}>NOM ET PRENOM</th>
                                    <th style={{ color: 'red' }}>EMAIL</th>
                                    <th style={{ color: 'red' }}>TELEPHONE</th>
                                    <th colSpan={2} style={{ color: 'red' }}><center>ACTIONS</center></th>

                                </tr>
                            </thead>
                            <tbody>
                                {records
                                    .map((si, index) => (
                                        <tr key={si.id_mani_site}>
                                            <td><center>{index + 1}</center></td>
                                            <td>{si.societe}</td>
                                            <td>{si.nom + " " + si.prenom}</td>
                                            <td>{si.mail}</td>
                                            <td>{si.telephone}</td>
                                            <td><center><button type='button' onClick={() => liste_site_manipu_get_data_by(si.id_mani_site)} className='btn btn-warning'  >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </center></td>
                                            <td><center><button type='button' onClick={() => confirm2(si.id_mani_site)} className='btn btn-danger'>
                                                <i className='fa fa-trash-o'></i>
                                            </button>
                                            </center></td>
                                        </tr>
                                    ))}
                            </tbody>

                        </table> */}

                        {/* <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={prevPage}
                                    >Prev</a>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href='#' className='page-link'
                                                onClick={() => changeCPage(n)}
                                            >{n}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={nextPage}
                                    >Next</a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </section>
            </section>}
            <Footer />
        </section>
    );
}

export default Manipulateurs;