import React, { useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import { Paginator } from 'primereact/paginator';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Footer from '../Footer';
import { useContext } from 'react';
import { ThemeContext } from '../../context/ContextHeader';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FaFileExcel } from "react-icons/fa6";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';
import ColumnWithAsyncData from './ColumnWithAsyncData';
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import { City, Country, State } from 'country-state-city';
import { RadioButton } from 'primereact/radiobutton';
import PhoneInput from 'react-phone-input-2';
function Sites() {
    const navigate = useNavigate();
    const { sites, setSites } = useContext(ThemeContext);
    //const [sites, setSites] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 15;
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = sites.slice(firstIndex, lastIndex);
    const npage = Math.ceil(sites.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    // const [filteredData, setFilteredData] = useState([]);
    const [query, setQuery] = useState("");
    const dt = useRef(null);
    // const toast = useRef(null);
    const [data_get, setData_get] = useState([]);
    const [id, setId] = useState(0);
    // const [societe, setSociete] = useState("");
    const [loading, setLoading] = useState(true);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const [visible, setVisible] = useState(false);

    // const [sites, setSites] = useState([]);
    const [country, setCountry] = React.useState('');
    const [region, setRegion] = React.useState([]);
    const [city, setCity] = useState([]);
    const [countries, setCountries] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDatatwo, setFilteredDatatwo] = useState([]);
    const [code, setCode] = useState("");
    const [societe, setSociete] = useState("");
    const [type, setType] = useState("");
    const [email, setEmail] = useState("");
    const [nomresponsable, setNomresponsable] = useState("");
    const [fonction, setFonction] = useState("");
    const [adresselocal, setAdresselocal] = useState("");
    const [pays, setPays] = useState('');
    const [departement, setDepartement] = useState('');
    const [ville, setVille] = useState('');
    const [telephone, setTelephone] = useState("");
    const [description, setDescription] = useState("");
    const [logo, setLogo] = useState("");
    const [entete, setEntete] = useState("");
    const [statut, setStatut] = useState(1);
    const toast = useRef(null);
    const [checked, setChecked] = useState(false);
    const [ingredient, setIngredient] = useState('');
    const [value, setValue] = useState();
    const [phone, setPhone] = useState("+242");
    const [message_mail, setMessage_mail] = useState("");
    const [message_societe, setMessage_societe] = useState("");
    const [message_type, setMessage_type] = useState("");
    const [message_telephone, setMessage_telephone] = useState("");
    const [message_adresse, setMessage_adresse] = useState("");
    const [message_responsable, setMessage_responsable] = useState("");
    const [message_fonction, setMessage_fonction] = useState("");
    const [message_pays, setMessage_pays] = useState("");
    const [message_departement, setMessage_departement] = useState("");
    const [message_ville, setMessage_ville] = useState("");
    const [booleanStatus, setBooleanStatus] = useState(true);
    const [id_site, setId_site] = useState(null);

    const removeIdenticalCharacters = (str) => {
        return str.replace(/(.)\1+/g, '$1');
    }

    const updateForm = (data) => {
        setBooleanStatus(true);
        setVisible(true);
        setId_site(data.id)
        setSociete(data.societe);
        setAdresselocal(data.adresselocal);
        setTelephone(data.telephone);
        setPays(data.pays);
        setVille(data.ville);
        setDepartement(data.departement);
        setType(data.type);
        setEmail(data.email);
        setNomresponsable(data.nomresponsable);
        setFonction(data.fonction);
        setCode(data.code);
        data.statut == "1" ? setIngredient("oui") : data.statut == "2" ? setIngredient("serafi") : setIngredient("non")

    }
    const handleInputSociete = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setSociete(value)
            setMessage_societe("");
        } else {
            setSociete("")
        }
    }
    const cleanFiels = () => {
        setSociete("");
        setAdresselocal("");
        setTelephone("");
        setPays("");
        setVille("");
        setType("");
        setEmail("");
        setNomresponsable("");
        setFonction("");
        setCode("");

    }
    const handleInputType = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setType(value)
            setMessage_type("");
        } else {
            setType("")
        }
    }
    const handleInputEmail = (event) => {
        const value = event.target.value;
        if (value !== "") {
           
                setEmail(value)
                setMessage_mail("");

           
        } else {
            setEmail("")
        }
    }

    const handleInputEmailLeave = (event) => {
        const value = event.target.value;
        if (value !== "") {
            if(EmailValidation(value)){
                setEmail(value)
                setMessage_mail("");
            }else{
                // setEmail(value)
                setMessage_mail("Le mail n'est pas valid");
            }
           
        } else {
            setEmail("")
        }
    }
    const handleInputDepartement = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setDepartement(value)
            setMessage_departement("");
        } else {
            setDepartement("")
        }
    }
    const handleInputAdresse = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setAdresselocal(value)
            setMessage_adresse("");
        } else {
            setAdresselocal("")
        }
    }
    const handleInputPays = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setPays(value)
            setMessage_pays("");
        } else {
            setPays("")
        }
    }
    const handleInputVille = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setVille(value)
            setMessage_ville("");
        } else {
            setVille("")
        }
    }
    const handleInputResponsable = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setNomresponsable(value)
            setMessage_responsable("");
        } else {
            setNomresponsable("")
        }
    }
    const handleInputFonction = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setFonction(value)
            setMessage_fonction("");
        } else {
            setFonction("")
        }
    }
    const handleInputTelephone = (event) => {
        const value = event.target.value;
        if (value !== "") {
            setTelephone(value)
            setMessage_telephone("");
        } else {
            setTelephone("")
        }
    }
    const validationFields = () => {
        // if (email == "") {
        //     setMessage_mail("Veuillez saisir le mail");
        // }
        if (societe == "") {
            setMessage_societe("Veuillez saisir la société");

        }
        if (type == "") {
            setMessage_type("Veuillez saisir le type de société");

        }
        // if (telephone == "") {
        //     setMessage_telephone("Veuillez saisir le N° téléphone");

        // }
        // if (adresselocal == "") {
        //     setMessage_adresse("Veuillez saisir l'adresse");

        // }
        // if (nomresponsable == "") {
        //     setMessage_responsable("Veuillez saisir le nom responsable");

        // }
        // if (fonction == "") {
        //     setMessage_fonction("Veuillez saisir la fonction");

        // }
        // if (pays == "") {
        //     setMessage_pays("Veuillez sélectionner le pays");

        // }
        // if (departement == "") {
        //     setMessage_departement("Veuillez saisir le département");

        // }
        // if (ville == "") {
        //     setMessage_ville("Veuillez saisir la ville");

        // }
    }
    const handleNomChange = (e) => {
        setNomresponsable(e.target.value)
    }




    const EmailValidation = (email) => {
        const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
        if (regex.test(email)) {
            //setMessage_mail("Mail is valid");

            return true;
        } else if (!regex.test(email) && email !== "") {
            setMessage_mail("le mail n'est pas valide");
            return false;
        } else {
            setMessage_mail("");
            return false;
        }
    };

    const handleInputSocieteChange = (event) => {
        const value = event.target.value;
        // Use a regular expression to allow only uppercase letters
        const regex = /^[A-Z]*$/;
        if (regex.test(value)) {
            setSociete(value);
        }
    };

    const handleInputChangeNumberTel = (event) => {
        const value = event.target.value;
        // Use a regular expression to allow only numbers (including negative numbers)
        const regex = /^-?\d*$/;

        if (regex.test(value)) {
            // If the input value contains only numbers or negative numbers,
            // update the telephone state with the value.
            setTelephone(value);
            setMessage_telephone(""); // Clear any previous error messages.
        } else {
            // If the input value is invalid (contains non-numeric characters),
            // do not update the telephone state and display an error message.
            setTelephone("");
            // setMessage_telephone("Veuillez saisir le numéro");
        }
    };

    const handleInputChangeMail = (event) => {
        const value = event.target.value;
        if (value !== "") {
            EmailValidation(value)
        }


    };
    const show = () => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: 'message' });
    };
    const handleCityChange = (val) => {
        setCity(val);
    };
    const handleCountryChange = (e) => {
        const name_get = e.target.value;
        if (name_get !== "") {
            setPays(name_get);
            setMessage_pays("");
        } else {
            setPays("")
        }
        const filtered = countries.filter((item) => item.name === name_get);
        setFilteredData(filtered);

        filtered.forEach((filter) => {
            const states = State.getStatesOfCountry(filter.isoCode);
            const phonecode = filter.phonecode;
            setRegion(states);
            // setCode(phonecode)
        });
    };


    const handleRegionChange = (e) => {
        const name_get = e.target.value;
        if (name_get !== "") {
            setDepartement(name_get);
            setMessage_departement("");
        } else {
            setDepartement("")
        }
        const filtered = region.filter((item) => item.name === name_get);
        setFilteredDatatwo(filtered);
        filtered.forEach((filter) => {
            const states = City.getCitiesOfState(filter.countryCode, filter.isoCode);
            setCity(states);
            console.log("Region: ", states);
        });
    };
    const handleVilleChange = (e) => {
        const name_get = e.target.value;

        if (name_get !== "") {
            setVille(name_get);
            setMessage_ville("");
        } else {
            setVille("")
        }
    }


    const addConfirm = () => {
        setVisible(false);
        if (!ingredient) {
            // setVisible(true);

            Swal.fire({
                title: "Avertissement",
                text: "Veuillez cocher les options de validation !",
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.isConfirmed) {
                    setVisible(true);
                }
            });
        
        } else {
            Swal.fire({
                title: "Question",
                text: "Voulez-vous enregistrer ce site",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non"
            }).then((result) => {
                if (result.isConfirmed) {

                    add_site_state();
                } else {
                    setVisible(true);
                }
            });
        }


    }
    const add_site_state = async () => {
        try {

            const site_info = {

                societe: societe.replace(' ', '_').toUpperCase(),
                type: type,
                email: email,
                nomresponsable: nomresponsable,
                fonction: fonction,
                adresselocal: adresselocal,
                pays: pays,
                ville: ville,
                telephone: telephone,
                description: description,
                statut: ingredient == "oui" ? 1 : ingredient == "serafi" ? 2 : 0,
                code: (phone),
                departement: departement

            }

            validationFields();


            if (societe != "" && type != "" && pays != "" && ville != "" && pays && departement) {
                if (ingredient) {
                    const response = await axios.post(URL_AXIOS + "add_sites", site_info);

                    if (response.data.status == 200) {
                        Swal.fire({
                            title: "Réussi !",
                            text: "Le site a été enregistré avec succès !",
                            icon: "success"
                        });

                        cleanFiels();
                        setVisible(false);
                        liste_site();
                    } else {
                        // // toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Error 12!' });
                        // alert(JSON.stringify(site_info))
                    }
                } else {



                    setVisible(true);
                }

            } else {
                Swal.fire({
                    title: "Info !",
                    text: "veuillez renseigner les champs obligatoires svp !",
                    icon: "warning"
                });
                setVisible(true);
            }


        } catch (error) {
            console.log(error.message)
        }



    }


    const updateConfirm = () => {

        setVisible(false);
        Swal.fire({
            title: "Question",
            text: "Voulez-vous mettre à jour ce site",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui",
            cancelButtonText: "Non"
        }).then((result) => {
            if (result.isConfirmed) {
                if (id_site) {
                    update_site_state(id_site);
                    setVisible(false)
                }

            } else {
                setVisible(true)
            }
        });
    }

    const update_site_state = async (id) => {
        try {
            const site_info = {

                societe: societe.replace(' ', '_').toUpperCase(),
                type: type,
                email: email,
                nomresponsable: nomresponsable,
                fonction: fonction,
                adresselocal: adresselocal,
                pays: pays,
                ville: ville,
                telephone: telephone,
                description: description,
                statut: ingredient == "oui" ? 1 : ingredient == "serafi" ? 2 : 0,
                code: removeIdenticalCharacters(("+" + phone)),
                departement: departement

            }
            validationFields();
            if (EmailValidation(email)) {
                if (societe != "" || type != "" || email != "" || nomresponsable != "" || fonction != "" || adresselocal != "" || pays != "" || ville != "" || telephone != "" || phone != "") {
                    const response = await axios.post(URL_AXIOS + `update_site_status/${id}`, site_info);

                    if (response.data.status === 200) {
                        Swal.fire({
                            title: "Réussi!",
                            text: "Les informations du site ont été modifiées avec succès !",
                            icon: "success"
                        });
                        liste_site();

                    } else {
                        Swal.fire({
                            title: "Erreur!",
                            text: "La modificaion a échouée!",
                            icon: "error"
                        });
                    }
                } else {
                    toast.current.show({ severity: 'warning', summary: 'warning', detail: 'veuillez renseigner les champs obligatoires svp !' });
                }
            }
        } catch (error) {
            alert("Erreur " + error.message)
        }



    }
    useEffect(() => {

        const fetchCountries = async () => {
            try {
                //const response = await axios.get('https://api.countrystatecity.in/v1/countries');
                setCountries(Country.getAllCountries());
            } catch (error) {
                console.log(error);
            }
        };

        fetchCountries();

        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);

    const confirm2 = (id, societe) => {

        Swal.fire({
            title: "Questions ?",
            text: "Voulez-vous supprimer ce site ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'Non',
            confirmButtonText: "Oui"
        }).then((result) => {
            if (result.isConfirmed) {

                setId(id);
                setSociete(societe);
                delete_site(id);
            }
        });

    };

    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status === 200) {
                setSites(response.data.sites);
                setLoading(false)
                //setLogin(response.data.sites.length)
                //setFilteredData(response.data.sites);
            }
        } catch (Error) {

        }

    }
    const delete_site = async (id) => {
        try {

            const response = await axios.post(URL_AXIOS + `delete_site/${id}`);
            if (response.data.status === 200) {
                Swal.fire({
                    title: "Réussi !",
                    text: "Le site a été supprimé avec success!",
                    icon: "success"
                });
                liste_site();

            } else {
                Swal.fire({
                    title: "Echec !",
                    text: "Le site n'a pas été supprimé avec success!" + response.data.message,
                    icon: "error"
                });
            }
        } catch (Error) {

        }

    }
    const addSite = () => {
        setBooleanStatus(false);
        setVisible(true);

        setId_site("")
        setSociete("");
        setAdresselocal("");
        setTelephone("");
        setPays("");
        setVille("");
        setDepartement("");
        setType("");
        setEmail("");
        setNomresponsable("");
        setFonction("");
        setCode("");
    }

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(sites);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'sites');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>
                <button type='button' onClick={() => updateForm(data)} className='btn btn-warning'  >
                    <i className='fa fa-edit'></i>
                </button>
                <button type='button' style={{ marginLeft: 10 }} onClick={() => confirm2(data.id, data.societe)} className='btn btn-danger'>
                    <i className='fa fa-trash-o'></i>
                </button>
            </div>
        )
    }
    useEffect(() => {
        liste_site();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }

    }, []);

    return (
        <section id="container">
            <Toast ref={toast} />
            <ConfirmDialog />
            <Header />
            <AsideBar username={usernameconnected} />
            <Dialog header={booleanStatus ? "Modification site" : "Nouveau site"} headerClassName='bg-[#4ecdc4] text-white' visible={visible} style={{ width: 'auto' }} onHide={() => { if (!visible) return; setVisible(false); }}>
                <form role="form" class="form-horizontal style-form mt-[20px]">
                    <div class="form-group ">

                        <div class="col-lg-6">
                            <input type="text" value={societe} onChange={handleInputSociete} placeholder="Centre *" class="form-control" />
                            {message_societe !== "" ? <label style={{ color: 'red' }}>{message_societe}</label> : ""}
                        </div>
                        <div class="col-lg-6">
                            <select className='form-control' placeholder='Type *' value={type} onChange={handleInputType}>
                                <option>Type *</option>
                                <option value="Centre imagerie">Centre imagerie</option>
                                <option value="Centre médical">Centre médical</option>
                                <option value="Clinique médicale">Clinique médicale</option>
                                <option value="Hôpital">Hôpital</option>
                                <option value="Laboratoire d'analyse médicale">Laboratoire d'analyse médicale</option>
                                <option value="Plateforme d'interpretation">Plateforme d'interpretation</option>
                            </select>
                            {message_type !== "" ? <label style={{ color: 'red' }}>{message_type}</label> : ""}
                        </div>
                    </div>
                    <div class="form-group ">

                        <div class="col-lg-6">
                            <input type="email" required value={email} onBlur={handleInputEmailLeave} placeholder="Email " onChange={handleInputEmail} class="form-control" />
                            {message_mail !== "" ? <label style={{ color: 'red' }}>{message_mail}</label> : ""}
                        </div>

                        <div class="col-lg-6">
                            <input type="text" value={adresselocal} onChange={handleInputAdresse} placeholder="Adresse " class="form-control" />
                            {message_adresse !== "" ? <label style={{ color: 'red' }}>{message_adresse}</label> : ""}
                        </div>
                    </div>
                    <div class="form-group">

                        <div class="col-lg-4">

                            <select className='form-control' value={pays} onChange={handleCountryChange} >
                                <option value=""> Sélectionner un pays *</option>
                                {countries.map((country) => (
                                    <option key={country.isoCode} value={country.name}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                            {message_pays !== "" ? <label style={{ color: 'red' }}>{message_pays}</label> : ""}

                        </div>
                        <div class="col-lg-4">
                            <select className='form-control' value={departement} onChange={handleRegionChange} >
                                <option value="">Sélectionner un département *</option>
                                {region.map((region) => (
                                    <option key={region.isoCode} value={region.name}>
                                        {region.name}
                                    </option>
                                ))}
                            </select>
                            {message_departement !== "" ? <label style={{ color: 'red' }}>{message_departement}</label> : ""}


                        </div>
                        <div class="col-lg-4">

                            <select className='form-control' value={ville} onChange={handleVilleChange} >
                                <option value="">Sélectionner une ville * </option>

                                {city.map((city) => (
                                    <option key={city.isoCode} value={city.name}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>

                            {message_ville !== "" ? <label style={{ color: 'red' }}>{message_ville}</label> : ""}

                        </div>
                    </div>
                    <div class="form-group">

                        <div class="col-lg-3">
                            <input type="text" value={nomresponsable} onChange={handleInputResponsable} placeholder="Responsable " id="l-name" class="form-control" />

                            {message_responsable !== "" ? <label style={{ color: 'red' }}>{message_responsable}</label> : ""}
                        </div>
                        <div class="col-lg-3">
                            <input type="text" value={fonction} onChange={handleInputFonction} placeholder="Fonction " id="l-name" class="form-control" />

                            {message_fonction !== "" ? <label style={{ color: 'red' }}>{message_fonction}</label> : ""}
                        </div>
                        <div class="col-lg-2" >

                            <PhoneInput
                                country={'cg'}
                                inputStyle={{ width: '100%' }}
                                enableSearch={true}
                                value={phone}
                                onChange={(phone) => setPhone(phone)}
                                placeholder="Code * "
                                countryCodeEditable={false}
                                disabled={false}
                                disableCountryCode={true}
                                inputProps={{
                                    required: true,

                                }}
                            />

                        </div>
                        <div class="col-lg-4">
                            <input type="text" value={telephone} onChange={handleInputChangeNumberTel} placeholder="Téléphone *" id="l-name" class="form-control" />

                            {message_telephone !== "" ? <label style={{ color: 'red' }}>{message_telephone}</label> : ""}
                        </div>

                    </div>
                    <div className="form-group w-full">

                        <div className="flex justify-center">
                            <label className="ml-2 mt-[5px]"> Status de validation :</label>
                            <RadioButton inputId="ingredient1" name="pizza" className='custom-radio' style={{ marginLeft: 10 }} value="oui" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'oui'} />
                            <label htmlFor="ingredient1" className="ml-2 mt-[5px] " >Oui</label>
                            <RadioButton inputId="ingredient2" className='custom-radio ' name="pizza" value="non"
                                onChange={(e) => setIngredient(e.value)} checked={ingredient === 'non'}
                                style={{ marginLeft: 10 }} />
                            <label htmlFor="ingredient2" className="ml-2 mt-[5px]">Non</label>
                            {/* <label className='ml-[10px] mt-[5px]'>|</label> */}
                            <RadioButton inputId="ingredient3" name="pizza" className='custom-radio' style={{ marginLeft: 10 }} value="serafi" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'serafi'} />
                            <label className="ml-2 mt-[5px]"> Serafi </label>
                        </div>





                    </div>
                    <div class="form-group">

                        <div class="col-lg-12 text-center">
                            <button class={booleanStatus ? "btn btn-warning w-[300px] form-control" : "btn btn-info w-[300px] form-control"} type="button"
                                onClick={() => booleanStatus ? updateConfirm() : addConfirm()}>{booleanStatus ? "Mettre à jour" : "Enregistrer"}</button>

                        </div>
                    </div>
                </form>
            </Dialog>
            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper">
                    <div className='row ' style={{ padding: 10, marginTop: 20 }}>
                        <div className="flex flex-row">
                            <Button className='btn btn-info' icon="pi pi-plus" onClick={addSite} label='Nouveau site' />
                            {/* <button type="button" className='flex justify-center btn btn-success' title='Exporter les données'
                                onClick={exportExcel}
                                style={{ marginLeft: 10, color: 'white' }}
                            >
                                <FaFileExcel />
                                <span style={{ marginLeft: 10 }}>Exporter les données</span>
                            </button> */}
                            <Button className='btn btn-success ml-[10px]' icon="pi pi-file-excel" onClick={exportExcel} label='Exporter les données' />
                        </div>
                        { /*  <div className='col col-md-4'>
                            <input type="text" className='form-control' placeholder='Recherche ...'
                                value={query} onChange={handleQueryText}
                                style={{ borderRadius: 100 }} />
    </div>*/}
                    </div>
                    <div class="row " style={{ padding: 10 }}>
                        <DataTable value={sites} paginator emptyMessage="Aucun site disponible!" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className="custom-datatable">
                            <Column header={() => { return <div style={{ marginLeft: 20, padding: '0 10px' }}></div> }}
                                bodyStyle={{ textAlign: 'center', padding: '0 10px' }}
                                body={(data) => { return (<Badge className='w-[12px] h-[12px]' value="" severity={data.statut == 1 ? "success" : data.statut == 0 ? "danger" : data.statut == 2 ? 'warning' : 'secondary'}></Badge>) }}
                                headerStyle={{ marginLeft: "100px", padding: '0 10px' }}
                                style={{ width: '5%', height: '40px', padding: '0 10px' }}></Column>
                            <Column body={(data) => <ColumnWithAsyncData data={data} />} header="CENTRE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field={'type'} header="TYPE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field={'email'} header="EMAIL" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='telephone' header="TELEPHONE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='nomresponsable' header="RESPONSABLE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            {/* <Column field='fonction' header="FONCTION" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column> */}
                            <Column field='ville' header="VILLE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px', padding: '0 10px' }}></Column>
                            <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px', padding: '0 10px' }}></Column>
                        </DataTable>
                        {/* <table ref={dt} class="table table-bordered table-striped table-condensed">
                            <thead>
                                <tr style={{ backgroundColor: 'white' }}>

                                    <th style={{ color: 'red' }}><center>#</center></th>
                                    <th style={{ color: 'red' }}>CENTRE</th>
                                    <th style={{ color: 'red' }}>TYPE</th>
                                    <th style={{ color: 'red' }}>EMAIL</th>
                                    <th style={{ color: 'red' }}>TELEPHONE</th>
                                    <th style={{ color: 'red' }}>RESPONSABLE</th>
                                    <th style={{ color: 'red' }}>FONCTION</th>
                                    <th style={{ color: 'red' }}>VILLE</th>

                                    <th style={{ color: 'red' }}>DATE</th>
                                    <th colSpan={2} style={{ color: 'red' }}><center>ACTIONS</center></th>

                                </tr>
                            </thead>
                            <tbody>
                                {records
                                    .map((si, index) => (
                                        <tr key={si.id}>

                                            <td><center>{index + 1}</center></td>
                                            <td>{si.societe}</td>
                                            <td>{si.type}</td>
                                            <td>{si.email}</td>
                                            <td>{si.telephone}</td>
                                            <td>{si.nomresponsable}</td>
                                            <td>{si.fonction}</td>
                                            <td>{si.ville}</td>

                                            <td>{si.created_at}</td>

                                            <td><center><button type='button' onClick={() => navigate(`/sites_edit/${si.id}`)} className='btn btn-warning'  >
                                                <i className='fa fa-edit'></i>
                                            </button>
                                            </center></td>
                                            <td><center><button type='button' onClick={() => confirm2(si.id, si.societe)} className='btn btn-danger'>
                                                <i className='fa fa-trash-o'></i>
                                            </button>
                                            </center></td>

                                        </tr>
                                    ))}
                            </tbody>

                        </table> */}

                        {/* <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={prevPage}
                                    >Prev</a>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href='#' className='page-link'
                                                onClick={() => changeCPage(n)}
                                            >{n}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={nextPage}
                                    >Next</a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </section>
            </section>}

            <Footer />
        </section >
    );
}

export default Sites;