import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Badge } from 'primereact/badge';
import { URL_AXIOS } from '../../URL/URL';
import { ThemeContext } from '../../context/ContextHeader';
import { useLocation } from 'react-router-dom';

const ColumnWithAsyncData = ({ data }) => {
    const location = useLocation();
    const [count, setCount] = useState(null);
    const {
        datedebut,
        datefin,setBool
    } = useContext(ThemeContext);



    useEffect(() => {
        const fetchCount = async () => {
            try {
                
                if (location.pathname == "/sites") {
                  
                    if (datedebut !== "" && datefin !== "") {
                      
                        const response = await axios.post(URL_AXIOS + `search_count_folder_by_center/${data.societe}/${datedebut}/${datefin}`);
                        if (response.data.status == 200) {
                            setCount(response.data.centresoins);
                            setBool(false);
                            // console.log(response.data.centresoins)
                        }
                    } else {
                        if (data.societe) {
                            const response = await axios.get(`${URL_AXIOS}count_folder_by_center/${data.societe}`);
                            if (response.data.status === 200) {
                                setCount(response.data.centresoins);
                            } else {
                                setCount(0);
                            }
                        } else {
                            setCount(0);
                        }
                    }
                }


            } catch (error) {
                console.error('Error fetching count:', error);
                setCount(0);
            }
        };

        fetchCount();
    }, [location.pathname,data.societe,datedebut,datefin]); // Appelle useEffect chaque fois que nomcentresoins change

    return (
        <div className="flex justify-between">
            <p>{data.societe}</p>
            <Badge value={count !== null ? count : 'Loading...'}></Badge>
        </div>
    );
};

export default ColumnWithAsyncData;
