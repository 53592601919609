import { Badge } from 'primereact/badge';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FiEdit } from "react-icons/fi";
import { MdOutlineFilterList } from "react-icons/md";
import { RxAvatar } from "react-icons/rx";
import { IoIosSend } from "react-icons/io";
import { RiAttachment2 } from "react-icons/ri";
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { ContextMenu } from 'primereact/contextmenu';
import { AiFillFilePdf, AiFillFileZip } from 'react-icons/ai';
import { FaFileAlt, FaFileWord, FaFileExcel } from "react-icons/fa";
import { URL_AXIOS, URL_MAIN } from '../../URL/URL';
import { ThemeContext } from '../../context/ContextHeader';
import Header from '../Header';
import AsideBar from '../AsideBar';
function Notifications() {
    const usernameconnected = sessionStorage.getItem("nom");
    const societe = sessionStorage.getItem("societe");
    const { id } = useParams();
    const {
        notifications, setNotifications,
        setCountNotif
    } = useContext(ThemeContext);
    const navigate = useNavigate();
    const [nomFichier, setNomFichier] = useState("");
    const user_id = sessionStorage.getItem("user_id");
    const site_id = sessionStorage.getItem("site_id");
    const [membres, setMembres] = useState([]);
    const [toggleOverlayBool, setToggleOverlayBool] = useState(true);
    const [name, setName] = useState('');
    const [notificationsAll, setNotificationsAll] = useState([]);
    const [notificationsData, setNotificationsData] = useState([]);
    const [display_last_message, setDisplay_last_message] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState(true);
    const [objet, setObjet] = useState("");
    const [text, setText] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [notificationsCount, setNotificationsCount] = useState([]);
    const messageContainerRef = useRef(null);
    const cm = useRef(null);
    const [selectedId, setSelectedId] = useState(0);
    const fileInputRef = useRef(null);
    const items = [
        {
            label: 'Modifier',
            icon: 'pi pi-file-edit',


        }
        ,
        {
            separator: true
        },

        {
            label: 'Supprimer',
            icon: 'pi pi-trash',
            command: () => {
                if (selectedId) {

                    delete_notification(selectedId);
                } else {

                }

            }
        }
    ];

    const onRightClick = (event, id) => {
        if (cm.current) {

            setSelectedId(id);
            cm.current.show(event);

        }
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log("Selected file:", file.name);
        }
    };

    const openFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Programmatically click the file input
        }
    };

    const liste_membres = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_user_site_centreAdmin');
            if (response.data.status === 200) {
                setMembres(response.data.users_sites);
            }
        } catch (Error) {

        }
    }
    const delete_notification = async (id_notification) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_notifications/${id_notification}`);
            if (response.data.status === 200) {

                liste_notificationLoad(id);
                liste_notification(user_id);

            }
        } catch (Error) {

        }

    }
    const addNotificat = async () => {
        try {

            if (text != "" && id) {
                const data_notifi = {
                    objet: "N/A",
                    contenu: text,
                    user_id_sender: user_id,
                    user_id_reception: id,
                    file: selectedFile
                }

                const res = await axios.post(URL_AXIOS + "addNotifications", data_notifi, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for FormData
                    },
                });
                if (res.data.status === 200) {

                    setSelectedFile("")
                    setText("");
                    liste_notificationLoad(id);
                    liste_notification(user_id);
                    lireMessageScroll();


                } else {
                    Swal.fire({
                        title: "Erreur !",
                        text: "L'enregistrement de la notification a échoué !",
                        icon: "error"
                    })
                }
            } else {
                Swal.fire({
                    title: "Erreur !",
                    text: "Veuillez renseigner tous les champs obligatoires !",
                    icon: "error"
                })
            }
        } catch (error) {
            // alert("Error saving : " + error.message)
        }

    }

    const toggleOverlay = () => {
        let div_overlay = document.querySelector('.overlayCustomize');
        if (div_overlay && toggleOverlayBool) {
            div_overlay.classList.remove("hidden");
            div_overlay.classList.add("overlayCustomize_anima");
        } else {
            div_overlay.classList.add("hidden");
            div_overlay.classList.remove("overlayCustomize_anima");
        }
        setToggleOverlayBool(!toggleOverlayBool)
    }

    const new_discussion = (name) => {
        let div_overlay = document.querySelector('.overlayCustomize');

        if (name) {
            setName(name);
            setToggleOverlayBool(false)
            if (div_overlay && !toggleOverlayBool) {
                div_overlay.classList.add("hidden");
                div_overlay.classList.remove("overlayCustomize_anima");
            }
        } else {
            setName("")
        }

    }
    const liste_notification = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId/${id}`);
            if (response.data.status === 200) {
                setNotifications(response.data.notifications);
                // setDataMembre(response.data);
                setNotificationsData(response.data.notificationsData);
                // setNotificationsAll(response.data.notificationsAll);
                // setNotification_recep(response.data.notification_recep);
                // setNotification_sender(response.data.notification_sender);
                setDisplay_last_message(response.data.display_last_message)
                setNotificationsCount(response.data.notificationsCount)
                setCountNotif(response.data.notificationsAllCount);
                setLoadingMessage(false);

            } else {
                setLoadingMessage(false);
            }
        } catch (Error) {
            setLoadingMessage(false);
        }
    }

    const search_message = (e) => {
        try {
            const value = e.target.value.toLowerCase();

            if (value) {
                const filtered = display_last_message.filter((d) =>
                    d.name.toLowerCase().includes(value)
                );
                setDisplay_last_message(filtered)
                console.log(filtered);
            } else {
                liste_notification(user_id)
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const search_membre = (e) => {
        try {
            const value = e.target.value.toLowerCase();

            if (value) {
                const filtered = membres.filter((d) =>
                    d.name.toLowerCase().includes(value)
                );
                setMembres(filtered)
                console.log(filtered);
            } else {
                liste_membres()
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const liste_notificationLoad = useCallback(async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId_load/${id}`);
            if (response.data.status === 200) {
                setNotificationsAll(response.data.display_last_message);
            }
        } catch (Error) {
            console.log("Erreur");
        }
    }, []);

    const updateStatus = async (id, user_id2) => {
        try {
            const response = await axios.put(URL_AXIOS + `updateStatus/${id}/${user_id2}`);
            if (response.data.status === 200) {
                liste_notification(user_id);

            }
        } catch (Error) {
            console.log("Erreur");
        }
    }

    const lireMessage = (name) => {
       
        setName(name)
        updateStatus(user_id, id);
        lireMessageScroll();
        // lireMessageScroll()
        // const messages = messageContainerRef.current.children;
        // const lastMessage = messages[messages.length - 1];
        // lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }


    // const lireMessageScroll = useCallback(() => {
    //     const messages = messageContainerRef.current.children;
    //     const lastMessage = messages[messages.length - 1];
    //     lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });

    // },[]);

    const lireMessageScroll = useCallback(() => {
        if (!messageContainerRef.current) return;

        const messages = messageContainerRef.current.children;
        if (messages.length === 0) return;

        const lastMessage = messages[messages.length - 1];
        if (lastMessage) {
            lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, []);


    // useEffect(() => {
    //     // Enable pusher logging - useful for debugging, but don't include in production
    //     Pusher.logToConsole = true;

    //     // Initialize Pusher with environment variables for app key and cluster
    //     const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    //         cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    //     });

    //     // Subscribe to the channel
    //     const channel = pusher.subscribe('channel-notifi');

    //     // Bind to the event and handle the data
    //     channel.bind('notificatEvent', (data) => {
    //         if (data) {
    //             if (data.user_id == user_id) {
    //                 liste_notification(data.user_id);
    //             }

    //         }
    //     });

    //     // Cleanup the Pusher subscription when the component unmounts
    //     return () => {
    //         pusher.unsubscribe('my-channel'); // Unsubscribe from the channel
    //         pusher.disconnect(); // Disconnect from Pusher
    //     };
    // }, []);

    const renderFilePreview = () => {
        if (!selectedFile) return null;

        const fileType = selectedFile.type;
        const fileExtension = name.split('.').pop().toLowerCase();
        if (fileType.startsWith("image/")) {
            return (
                <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Preview"
                    className="img-preview"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
            );
        } else if (fileType === "application/pdf") {
            return (
                <div className="file-icon">
                    <AiFillFilePdf size={50} color="red" />
                    <p>{selectedFile.name}</p>
                </div>
            );
        } else if (
            fileType === "application/x-rar-compressed" ||
            fileType === "application/zip"
        ) {
            return (
                <div className="file-icon">
                    <AiFillFileZip size={50} color="blue" />
                    <p>{selectedFile.name}</p>
                </div>
            );
        } else if (['doc', 'docx'].includes(fileExtension)) {
            return (
                <div className="file-icon cursor-pointer">
                    <FaFileWord size={50} color="blue" />
                </div>
            );
        } else if (['xls', 'xlsx'].includes(fileExtension)) {
            return (
                <div className="file-icon cursor-pointer">
                    <FaFileExcel size={50} color="green" />
                </div>
            );
        } else {
            return (
                <div className="file-icon">
                    <FaFileAlt size={50} color="gray" />
                    <p>{selectedFile.name}</p>
                </div>
            );
        }
    };

    const handleDownload = (e, val) => {
        e.preventDefault();
        //const getURL = getURLFolder;
        const downloadLink = document.createElement('a');
        downloadLink.href = val;
        downloadLink.download = val;
        downloadLink.target = "_blank";
        downloadLink.click();
    };
    const renderFilePreviewLoad = (name) => {
        if (!name) return null;
        const filename = URL_MAIN + "notifications_files/" + name;
        // Get the file extension
        const fileExtension = name.split('.').pop().toLowerCase();

        if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'].includes(fileExtension)) {
            return (
                <img
                    src={`${URL_MAIN + "notifications_files"}/${name}`} // Adjust the path based on your server setup
                    alt="Preview"
                    className="img-preview"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
            );
        } else if (fileExtension === 'pdf') {
            return (
                <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
                    <AiFillFilePdf size={50} color="red" />

                </div>
            );
        } else if (['rar', 'zip', '7z'].includes(fileExtension)) {
            return (
                <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
                    <AiFillFileZip size={50} color="blue" />

                </div>
            );
        } else if (['doc', 'docx'].includes(fileExtension)) {
            return (
                <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
                    <FaFileWord size={50} color="blue" />
                </div>
            );
        } else if (['xls', 'xlsx'].includes(fileExtension)) {
            return (
                <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
                    <FaFileExcel size={50} color="green" />
                </div>
            );
        } else {
            return (
                <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
                    <FaFileAlt size={50} color="gray" />

                </div>
            );
        }
    };

    // useEffect(() => {
    //     if (id) {
    //         liste_notificationLoad(id);

    //         lireMessageScroll();
    //     }
    // }, [id, lireMessageScroll]);
    useEffect(() => {
        const loadNotifications = async () => {
            if (id) {
                await liste_notificationLoad(id); // Wait for data to load
                lireMessageScroll(); // Scroll to the last message
            }
        };
        loadNotifications();
    }, [id, liste_notificationLoad, lireMessageScroll]);
    
    useEffect(() => {
        if (notifications.length > 0) {
            lireMessageScroll();
        }
    }, [notifications, lireMessageScroll]);
    useEffect(() => {
        if (site_id && user_id) {
            liste_notification(user_id);
            liste_membres();
        }
    }, [site_id]);
    useEffect(() => {
        // fetchMembres();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);

    useEffect(() => {
        const handleMouseDown = (event) => {
            const div_overlay = document.querySelector('.overlayCustomize');
            if (div_overlay && !div_overlay.contains(event.target)) {
                div_overlay.classList.add("hidden");
            }
        };

        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);
    // useEffect(() => {
    //     // Attach the event listener
    //     let div_overlay = document.querySelector('.overlayCustomize');
    //     document.addEventListener("mousedown", () => {
    //         div_overlay?.classList.add("hidden");

    //     });
    //     return () => {
    //         // Clean up the event listener
    //         document.removeEventListener("mousedown", () => {
    //             div_overlay?.classList.add("hidden");

    //         });
    //     };
    // }, []);
    return (
        <div >
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="container">
                <section id="main-content">
                    <section class="wrapper">
                        <div className='flex w-full relative mt-[30px]'>
                            <div className='w-[30%] bg-[#eaeaea] h-[300px]'>
                                <div className='flex p-[10px] justify-between'>
                                    <div className='w-[80%]'>
                                        <p className='text-[#4ecdc4] font-bold size-[25px]'>Discussions</p>
                                    </div>
                                    <div className='flex w-[20%] justify-between '>
                                        <div className=''>
                                            <FiEdit className='text-[#4ecdc4]  size-[20px] cursor-pointer' onClick={toggleOverlay} />
                                            <div className='hidden card absolute z-50 w-[350px] h-auto p-[10px] overlayCustomize'>
                                                <input type='text' onChange={search_membre} className='form-control' placeholder='Rechercher un nom' />
                                                <div className='mt-[10px] h-[400px] overflow-y-auto overflow-x-hidden'>
                                                    {membres.map((m, index) => {
                                                        return (
                                                            <Link to={`/lire-notification/${m.id}`} className='flex cursor-pointer hover:bg-gray-100' key={index} onClick={() => new_discussion(m.name)}>
                                                                <RxAvatar className='w-[50px] h-[50px]' />
                                                                <h1 className='p-[20px]'>{m.name}</h1>
                                                            </Link>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>


                                        <MdOutlineFilterList className='text-[#4ecdc4] size-[20px]' />
                                    </div>
                                </div>

                                <div className='p-[10px]'>
                                    <IconField iconPosition="left">
                                        <InputIcon className="pi pi-search"> </InputIcon>
                                        <InputText onChange={(e) => search_message(e)} placeholder="Rechercher ou démarrer une discussion" className='w-full px-[30px] h-[30px]' />
                                    </IconField>
                                </div>
                                {loadingMessage ? <p className='p-[10px]'>Chargement...</p> : <div className='h-[300px]  overflow-x-hidden overflow-y-auto p-[10px]'>
                                    {display_last_message.length == 0 && <div><p className='text-red-600 font-bold'>Aucun message</p></div>}
                                    {display_last_message.map((data, index) => {
                                        // const displayName = return_value_display_user(display_last_message[data.SC],data.id_notification);

                                        return (
                                            <div className="parent-container" onClick={() => lireMessage(data.name)}>
                                                <Link
                                                    to={`/lire-notification/${data.DataUsers.id_user_recep == user_id ? data.DataUsers.user_id_sender : data.DataUsers.id_user_recep}`}
                                                    className="flex p-2 w-full  cursor-pointer hover:bg-gray-100 hover:rounded-full focus:bg-gray-100"

                                                >
                                                    <div className="flex w-full" >
                                                        {/* Profile Picture */}
                                                        <div className="w-[50px] h-[50px] bg-white rounded-full">
                                                            {/* <img
                                                className="rounded-full w-full h-full"
                                                src="https://img.freepik.com/vecteurs-libre/femme-elegante-veste-rouge_1308-176631.jpg?semt=ais_hybrid"
                                                alt={data.name}
                                            /> */}

                                                            <RxAvatar className='w-[50px] h-[50px]' />
                                                        </div>

                                                        {/* Name and Message */}
                                                        <div className="flex flex-col p-2 ml-2 relative w-full" key={index}>
                                                            <h1 className="text-[#4ecdc4] font-bold" >{data.name}</h1>
                                                            <div className="text-[#4ecdc4]  flex justify-between" >
                                                                <p className=" text-black">
                                                                    {data.DataUsers.contenu.length > 35
                                                                        ? `${data.DataUsers.contenu.substring(0, 35)}...`
                                                                        : data.DataUsers.contenu}
                                                                </p>

                                                                {data.unread_count > 0 && <Badge value={data.unread_count} severity="success"></Badge>}
                                                            </div>


                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </div>}





                            </div>
                            <div className='w-[1%]'>

                            </div>
                            <div className='w-[69%] bg-[#eaeaea]'>
                                <div className='p-[10px] flex border-b-2 border-b-[#4ecdc4]'>
                                    <div className='w-[40px] h-[40px] bg-white rounded-full'>
                                        {/* <img className='rounded-full w-full h-full' src='https://www.shutterstock.com/image-vector/portrait-beautiful-latin-american-woman-600nw-2071168457.jpg' /> */}
                                        <RxAvatar className='w-[40px] h-[40px]' />
                                    </div>
                                    <div className='p-[4px] ml-[10px] mt-[8px] relative w-full'>
                                        <h1 className='text-[#4ecdc4]  font-bold'>{name}</h1>
                                    </div>
                                </div>
                                <div ref={messageContainerRef} className='w-full flex flex-col h-[380px] overflow-x-hidden overflow-y-auto p-[10px]'>
                                    {notificationsAll.length == 0 && <div className='flex justify-center items-center'>
                                        Veuillez commencer une nouvelle discussion...
                                    </div>}
                                    {

                                        notificationsAll.map((noti, index) => {
                                            const isReceived = noti.DataUsers?.user_id_sender == id; // Check if the message is received
                                            const isSent = noti.DataUsers?.user_id_sender == user_id; // Check if the message is sent by the user

                                            return (
                                                <div key={index} className="mt-[10px] flex flex-col">

                                                    {/* Display Received Messages */}
                                                    {isReceived && (
                                                        <div onContextMenu={(e) => onRightClick(e, noti.DataUsers.id_notification)} className="flex flex-col bg-gray-100 h-auto w-[60%] rounded p-[10px]">
                                                            <div>
                                                                {noti.DataUsers.fichier_notification && renderFilePreviewLoad(noti.DataUsers.fichier_notification)}
                                                            </div>
                                                            <p className='text-wrap break-words'>{noti.DataUsers.contenu}</p>

                                                            <p className="ml-[70%] mt-[10px] text-gray-500 text-sm">{noti.DataUsers.created_at}</p>
                                                        </div>
                                                    )}

                                                    {/* Display Sent Messages */}
                                                    {isSent && (
                                                        <div onContextMenu={(e) => onRightClick(e, noti.DataUsers.id_notification)} className="flex justify-end mt-[10px]">
                                                            <div className="bg-[#4ecdc4] h-auto w-[60%] rounded p-[10px]">
                                                                <div>
                                                                    {noti.DataUsers.fichier_notification && renderFilePreviewLoad(noti.DataUsers.fichier_notification)}
                                                                </div>
                                                                <p className='text-wrap break-words'>{noti.DataUsers.contenu}</p>

                                                                <p className="ml-[70%] mt-[10px] text-gray-500 text-sm">{noti.DataUsers.created_at}</p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <ContextMenu model={items} ref={cm} breakpoint="767px" onHide={() => setSelectedId(undefined)} />
                                                </div>
                                            );
                                        })

                                    }


                                    {selectedFile && (
                                        <div className="file-preview mt-2">
                                            {renderFilePreview()}
                                        </div>
                                    )}
                                </div>
                                <div className='flex mt-[10px]'>
                                    <div className='flex w-[80%]'>

                                        <textarea type='text' value={text} onChange={(e) => setText(e.target.value)} placeholder='Taper message' className=' form-control mb-auto overflow-hidden'></textarea>
                                    </div>
                                    <div className='flex items-center ml-[20px]'>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                            className='form-control hidden'
                                        />
                                        <button type='button' onClick={addNotificat} className='btn btn-info size-[50px]'>
                                            <IoIosSend className='size-[30px]' />
                                        </button>
                                        <button
                                            type='button'
                                            onClick={openFileDialog}
                                            className='btn btn-primary size-[50px] ml-[10px]'
                                        >
                                            <RiAttachment2 className='size-[30px]' />
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div >
                    </section >
                </section >
            </section >
        </div >
    )
}

export default Notifications
