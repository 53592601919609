import { useCallback, useEffect, useState } from "react";
import { createContext } from "react";
import { URL_AXIOS } from "../URL/URL";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import useIdleTimer from "../components/admins/useIdleTimer";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {

    const [text, setText] = useState("");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [nbFoldersSend, setNbFoldersSend] = useState(0);
    const [datedebut, setDatedebut] = useState('');
    const [datefin, setDateFin] = useState('');
    const [dossiers, setDossiers] = useState([]);
    const [dossiersEn, setDossiersEn] = useState([]);
    const [disable, setDisable] = useState(false);
    const [disableOption, setDisableOption] = useState(false);
    const [disableAvis, setDisableAvis] = useState(false);
    const [disableRefresh, setDisableRefresh] = useState(false);
    const [items, setItems] = useState([]);
    const [nbFoldersRemove, setNbFoldersRemove] = useState(0);
    const [CompteExpertiseData, setCompteExpertiseData] = useState([]);
    const [dataAvisSend, setDataAvisSend] = useState([]);
    const [dataAvisReceive, setDataAvisReceive] = useState([]);
    const [medecinID, setMedecinID] = useState(membre_id);
    const [nbFoldersArchive, setNbFoldersArchive] = useState(0);
    const [nbFolderAvisSend, setNbFolderAvisSend] = useState(0);
    const [nbFolderAvisReceive, setNbFolderAvisReceive] = useState(0);
    const [notifi, setNotifi] = useState([]);
    const [notifiRecep, setNotifiRecep] = useState([]);
    const [notifiLimit, setNotifiLimit] = useState([]);
    const [objet, setObjet] = useState("");
    const [contenu, setContenu] = useState("");
    const [name_recep, setName_recep] = useState("");
    const [societe_recep, setSociete_recep] = useState("");
    const [fichier_notification, setFichier_notification] = useState("");
    const [created_at, setCreated_at] = useState();
    const [name_sender, setName_sender] = useState("");
    const [societe_sender, setSociete_sender] = useState("");
    const [getURLFolder, setGetURLFolder] = useState('');
    const [dataMembre, setDataMembre] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [userID, setUserID] = useState(11);
    const [contentMrc, setContentMrc] = useState("");
    const [user_data, setUser_data] = useState([]);
    const [sites, setSites] = useState([]);
    const [membres, setMembres] = useState([]);
    const [siteNb, setSiteNb] = useState(0);
    const [userNb, setUserNb] = useState(0);
    const [membreNb, setMembreNb] = useState(0);
    const [dossiersTnB, setDossiersTnB] = useState(0);
    const [examens, setExamens] = useState([]);
    const [gains, setGains] = useState([]);
    const [siteMembre, setSiteMembre] = useState([]);
    const [siteManipulateurs, setSiteManipulateurs] = useState([]);
    const [mcr, setMcr] = useState([]);
    const [abonnementSite, setabonnementSite] = useState([]);
    const [DossiersRecu, setDossiersRecu] = useState([]);
    const [input, setInput] = useState("");
    const [dataExamenStatistique, setDataExamenStatistique] = useState([]);
    const [periodeContext, setPeriodeContext] = useState("");
    const [membreValueContext, setMembreValueContext] = useState(0);
    const [examen_total, setExamen_total] = useState(0);
    const [modalite, setModalite] = useState([]);
    const [dossiersCorbeille, setdossiersCorbeille] = useState([]);
    const [DossiersArchives, setDossiersArchives] = useState([]);

    const [boolDF, setBoolDF] = useState(true);
    const [boolResultat, setBoolResult] = useState(false);
    const [boolSites, setBoolSites] = useState(false);
    const [boolCentreSoins, setBoolCentreSoins] = useState(false);
    const [boolUsers, setBoolUsers] = useState(false);
    const [boolMembres, setBoolMembres] = useState(false);
    const [boolExamens, setBoolExamens] = useState(false);
    const [boolGains, setBoolGains] = useState(false);
    const [boolContrat, setBoolContrat] = useState(false);
    const [boolManipulateur, setBoolManipulateur] = useState(false);
    const [boolNotification, setBoolNotification] = useState(false);
    const [boolMcr, setBoolMcr] = useState(false);
    const [boolStatistiques, setBoolStatistiques] = useState(false);
    const [boolModalites, setBoolModalites] = useState(false);
    const [boolAgenda, setBoolAgenda] = useState(false);
    const [boolAbonnement, setBoolAbonnement] = useState(false);
    const [boolCorbeille, setBoolCorbeille] = useState(false);
    const [boolArchives, setBoolArchives] = useState(false);
    const [dossiersConfigGains, setDossiersConfigGains] = useState([]);
    const [centressoins, setCentressoins] = useState([]);
    const [boolcentresoins, setBoolcentresoins] = useState(false);
    const [bool, setBool] = useState(true);
    const [count, setCount] = useState(null);

    useIdleTimer(30 * 60 * 1000); // 15 minutes d'inactivité

    const liste_dossier_corbeille = useCallback(async () => {
        try {

            const response = await axios.get(URL_AXIOS + "liste_dossiers_retireAdmin");
            if (response.data.status === 200) {
                setdossiersCorbeille(response.data.dossier_send);


            }
        } catch (error) {
            // console.log("Erreur " + error.message);
        }
    }, [dossiersCorbeille])

    const liste_site_manipulateurs = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_manipulation');
            if (response.data.status == 200) {
                setSiteManipulateurs(response.data.vue_manipulateurs_site);

            }
        } catch (Error) {

        }

    }
    const lister_contrat = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'liste_membres_sites');
            if (response.data.status === 200) {
                setSiteMembre(response.data.data_membres_sites);

            }
        } catch (Error) {

        }
    };
    const liste_gains = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'liste_gains');
            if (response.data.status === 200) {
                setGains(response.data.gains);
            }
        } catch (Error) {

        }

    }
    const liste_examens = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_examen');
            if (response.data.status === 200) {
                setExamens(response.data.examens);
            }
        } catch (Error) {

        }

    }
    const liste_dossiers = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_dossiers');
            if (response.data.status == 200) {
                setDossiersTnB(response.data.dossier_send.length);
                setDossiers(response.data.dossier_send);

            }
        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }
    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status === 200) {
                setSiteNb(response.data.sites.length);
                setSites(response.data.sites);
                //setFilteredData(response.data.sites);
            }
        } catch (Error) {

        }

    }
    const liste_site_users = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'users_sites');
            if (response.data.status === 200) {
                setUserNb(response.data.users_sites.length);
                setUser_data(response.data.users_sites);
            }
        } catch (Error) {

        }

    }

    const liste_membres = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'membres');
            if (response.data.status === 200) {
                setMembreNb(response.data.membres.length);
                setMembres(response.data.membres);
            }
        } catch (Error) {

        }

    }

    const liste_abonnement_sites = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_abonnements_sites');
            if (response.data.status === 200) {
                setabonnementSite(response.data.vue_sites_abonnements);
            }
        } catch (Error) {

        }

    }



    const liste_dossiers_all_resultat = async () => {
        try {
            const response = await axios.get(URL_AXIOS + "liste_resultatAdmin");
            if (response.data.status == 200) {
                setDossiersRecu(response.data.vue_dossier_interpreter);

            }
        } catch (error) {
            // alert("Erreur " + error.message);
        }
    }

    const liste_dossiers_all_Not_Gains_Config = async () => {
        try {
            const response = await axios.get(URL_AXIOS + "liste_dossiersConfigGains");
            if (response.data.status == 200) {
                setDossiersConfigGains(response.data.dossier_send);

            }
        } catch (error) {
            // alert("Erreur " + error.message);
        }
    }

    const BulleAvisSend = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_envoye/${val}`);
                if (response.data.status === 200) {
                    setNbFolderAvisSend(response.data.data_avis_send.length);

                } else {

                }
            }

        } catch (error) {

        }
    }
    useEffect(() => {
        liste_dossier_corbeille();
    }, []);

    useEffect(() => {
        liste_dossiers_all_Not_Gains_Config();
    }, [dossiersConfigGains]);

    // const BulleAvisReceive = async (val) => {
    //     try {
    //         if (val !== null) {
    //             const response = await axios.get(URL_AXIOS + `liste_avis_sollicite/${val}`);
    //             if (response.data.status === 200) {
    //                 setNbFolderAvisReceive(response.data.data_avis_sollicite.length);


    //             } else {

    //             }
    //         }

    //     } catch (error) {

    //     }
    // }


    const liste_notification = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `CountNumberOFNotification/${user_id}`);
            if (response.data.status === 200) {
                setNotifi(response.data.notificationSend);
                setNotifiLimit(response.data.notificationsLimit);
            }
        } catch (Error) {

        }
    }

    const liste_notificationByUsers = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `ListeNotificationReception/${user_id}`);
            if (response.data.status === 200) {
                setNotifiRecep(response.data.notifications);
                // setDataMembre(response.data);
                setGetURLFolder(response.data.folderUrl)


            }
        } catch (Error) {

        }
    }

    const liste_dossiersArchive_by_site = useCallback(async () => {
        try {
            const response = await axios.get(URL_AXIOS + "liste_resultatArchiveByAdmin");
            if (response.data.status == 200) {
                setDossiersArchives(response.data.vue_dossier_interpreter);

            }
        } catch (error) {
            // console.log("Erreur " + error.message);
        }
    }, [])
    useEffect(() => {
        liste_dossiersArchive_by_site();
    }, []);
    useEffect(() => {
        liste_dossiers();
    }, []);
    useEffect(() => {
        liste_notification();
    }, [user_id]);
    useEffect(() => {
        liste_notificationByUsers();
    }, [user_id, notifiRecep]);
    useEffect(() => {
        liste_site();
    }, []);
    useEffect(() => {

        liste_site_users();
    }, []);
    useEffect(() => {
        liste_membres();
    }, []);
    useEffect(() => {
        liste_examens();
    }, []);
    useEffect(() => {
        liste_gains();
    }, []);
    useEffect(() => {
        lister_contrat();
    }, []);

    useEffect(() => {
        liste_site_manipulateurs();
    }, []);
    useEffect(() => {
        liste_abonnement_sites();
    }, [abonnementSite]);
    useEffect(() => {

        liste_dossiers_all_resultat();
    }, []);

    return (
        <ThemeContext.Provider value={{
            text, setText,
            datedebut, setDatedebut,
            datefin, setDateFin,
            dossiers, setDossiers,
            dossiersEn, setDossiersEn,
            items, setItems,
            nbFoldersSend, setNbFoldersSend,
            nbFoldersRemove, setNbFoldersRemove,
            disable, setDisable,
            disableAvis, setDisableAvis,
            CompteExpertiseData, setCompteExpertiseData,
            dataAvisSend, setDataAvisSend,
            dataAvisReceive, setDataAvisReceive,
            medecinID, setMedecinID,
            nbFoldersArchive, setNbFoldersArchive,
            nbFolderAvisSend, setNbFolderAvisSend,
            nbFolderAvisReceive, setNbFolderAvisReceive,
            notifi, setNotifi,
            notifiLimit, setNotifiLimit,
            objet, setObjet,
            contenu, setContenu,
            name_recep, setName_recep,
            societe_recep, setSociete_recep,
            fichier_notification, setFichier_notification,
            created_at, setCreated_at,
            name_sender, setName_sender,
            societe_sender, setSociete_sender,
            getURLFolder, setGetURLFolder,
            dataMembre, setDataMembre,
            notifications, setNotifications,
            notifiRecep, setNotifiRecep,
            userID, setUserID,
            disableRefresh, setDisableRefresh,
            contentMrc, setContentMrc,
            userNb, setUserNb,
            siteNb, setSiteNb,
            membreNb, setMembreNb,
            dossiersTnB, setDossiersTnB,
            user_data, setUser_data,
            sites, setSites,
            membres, setMembres,
            examens, setExamens,
            gains, setGains,
            siteMembre, setSiteMembre,
            siteManipulateurs, setSiteManipulateurs,
            mcr, setMcr,
            abonnementSite, setabonnementSite,
            DossiersRecu, setDossiersRecu,
            input, setInput,
            dataExamenStatistique, setDataExamenStatistique,
            periodeContext, setPeriodeContext,
            membreValueContext, setMembreValueContext,
            examen_total, setExamen_total,
            modalite, setModalite,
            dossiersCorbeille, setdossiersCorbeille,
            boolResultat, setBoolResult,
            boolCorbeille, setBoolCorbeille,
            boolAbonnement, setBoolAbonnement,
            boolAgenda, setBoolAgenda,
            boolModalites, setBoolModalites,
            boolStatistiques, setBoolStatistiques,
            boolMcr, setBoolMcr,
            boolNotification, setBoolNotification,
            boolManipulateur, setBoolManipulateur,
            boolContrat, setBoolContrat,
            boolGains, setBoolGains,
            boolExamens, setBoolExamens,
            boolMembres, setBoolMembres,
            boolUsers, setBoolUsers,
            boolSites, setBoolSites,
            boolDF, setBoolDF,
            boolArchives, setBoolArchives,
            DossiersArchives, setDossiersArchives,
            dossiersConfigGains, setDossiersConfigGains,
            disableOption, setDisableOption,
            boolCentreSoins, setBoolCentreSoins,
            centressoins, setCentressoins,
            boolcentresoins, setBoolcentresoins,
            count, setCount,
            bool, setBool
        }}>
            {children}
        </ThemeContext.Provider>
    )
}