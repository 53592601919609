import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../context/ContextHeader';



function AsideBar(props) {
    const [stat, setStat] = useState("marvin");
    const navigate = useNavigate();
    const location = useLocation();
    // const [boolDF, setBoolDF] = useState(true);
    // const [boolResultat, setBoolResult] = useState(false);
    // const [boolSites, setBoolSites] = useState(false);
    // const [boolUsers, setBoolUsers] = useState(false);
    // const [boolMembres, setBoolMembres] = useState(false);
    // const [boolExamens, setBoolExamens] = useState(false);
    // const [boolGains, setBoolGains] = useState(false);
    // const [boolContrat, setBoolContrat] = useState(false);
    // const [boolManipulateur, setBoolManipulateur] = useState(false);
    // const [boolNotification, setBoolNotification] = useState(false);
    // const [boolMcr, setBoolMcr] = useState(false);
    // const [boolStatistiques, setBoolStatistiques] = useState(false);
    // const [boolModalites, setBoolModalites] = useState(false);
    // const [boolAgenda, setBoolAgenda] = useState(false);
    // const [boolAbonnement, setBoolAbonnement] = useState(false);
    // const [boolCorbeille, setBoolCorbeille] = useState(false);







    const {
        userNb, setUserNb,
        siteNb, setSiteNb,
        membreNb, setMembreNb,
        dossiersTnB, setDossiersTnB,
        DossiersRecu, dossiersCorbeille,
        dossiers,
        boolDF, setBoolDF,
        boolResultat, setBoolResult,
        boolCorbeille, setBoolCorbeille,
        boolAbonnement, setBoolAbonnement,
        boolAgenda, setBoolAgenda,
        boolModalites, setBoolModalites,
        boolStatistiques, setBoolStatistiques,
        boolMcr, setBoolMcr,
        boolNotification, setBoolNotification,
        boolManipulateur, setBoolManipulateur,
        boolContrat, setBoolContrat,
        boolGains, setBoolGains,
        boolExamens, setBoolExamens,
        boolMembres, setBoolMembres,
        boolUsers, setBoolUsers,
        boolSites, setBoolSites,
        boolArchives, setBoolArchives,
        DossiersArchives, setDossiersArchives,
        boolCentreSoins, setBoolCentreSoins
    } = useContext(ThemeContext);

    useEffect(() => {
        if (location.pathname == "/dossiersTransferes") {
            setBoolDF(true);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/resultats") {
            setBoolDF(false);
            setBoolResult(true);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/sites") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(true);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/users") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(true);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/membres") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(true);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/examens") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(true);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/gains") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(true);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/contrats") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(true);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/manipulateurs") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(true);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/modelcompterendu") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(true);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/notifications") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(true);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/statistiques") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(true);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/modalite") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(true);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/agenda") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(true);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/abonnement") {
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(true);
            setBoolCorbeille(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname === "/corbeille") {
            setBoolCorbeille(true);
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolArchives(false);
            setBoolCentreSoins(false);
        } else if (location.pathname == "/archives") {
            setBoolArchives(true)
            setBoolDF(false);
            setBoolResult(false);
            setBoolSites(false);
            setBoolUsers(false);
            setBoolMembres(false);
            setBoolExamens(false);
            setBoolGains(false);
            setBoolContrat(false);
            setBoolManipulateur(false);
            setBoolNotification(false);
            setBoolMcr(false);
            setBoolStatistiques(false);
            setBoolModalites(false);
            setBoolAgenda(false);
            setBoolAbonnement(false);
            setBoolCorbeille(false);
            setBoolCentreSoins(false);
        }
    }, [location])
    return (
        <aside>
            <div id="sidebar" class="nav-collapse " >
                <ul class="sidebar-menu" id="nav-accordion">
                    <div class="centered p-[20px] ml-[30px]">
                        <a href="#"><img src="../../../logo.png" class="img-round" width="80" height={80} /></a>
                    </div>
                    <h5 class="centered">{props.username}</h5>
                    <hr className='ml-[20px]' style={{ width: "80%" }} />
                    <li class="sub-menu" style={boolDF == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" style={boolDF == true ? { color: 'white' } : {}} onClick={() => navigate('/dossiersTransferes')}>
                            <i class="fa fa-folder"></i>
                            <span>Dossiers transférés&nbsp;&nbsp;<span class="badge bg-theme">{dossiers.length > 0 ? dossiers.length : 0}</span></span>

                        </a>
                    </li>
                    {/* <li className="sub-menu" style={boolArchives == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/archives')} style={boolArchives == true ? { color: 'white' } : {}}>
                            <i class="fa fa-archive"></i>
                            <span>Archives&nbsp;&nbsp;<span class="badge bg-theme">{DossiersArchives.length > 0 ? DossiersArchives.length : 0}</span></span>
                        </a>
                    </li> */}
                    <li class="sub-menu" onClick={() => navigate('/resultats')} style={boolResultat == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" style={boolResultat == true ? { color: 'white' } : {}} >
                            <i class="fa fa-copy"></i>
                            <span>Résultats&nbsp;&nbsp;<span class="badge bg-theme">{DossiersRecu.length > 0 ? DossiersRecu.length : 0}</span></span>
                        </a>
                    </li>
                    <li class="sub-menu" style={boolSites == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>

                        <a href="javascript:;" onClick={() => navigate('/sites')} style={boolSites == true ? { color: 'white' } : {}}>

                            <i class="fa fa-home"></i>
                            <span>Sites&nbsp;&nbsp;<span class="badge bg-theme">{siteNb}</span></span>

                        </a>

                    </li>
                    <li class="sub-menu" style={boolUsers == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/users')} style={boolUsers == true ? { color: 'white' } : {}}>
                            <i class="fa fa-user"></i>
                            <span>Utilisateurs&nbsp;&nbsp;<span class="badge bg-theme">{userNb}</span></span>
                        </a>
                    </li>
                    <li class="sub-menu" style={boolMembres == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/membres')} style={boolMembres == true ? { color: 'white' } : {}}>
                            <i class="fa fa-users"></i>
                            <span>Membres&nbsp;&nbsp;<span class="badge bg-theme">{membreNb}</span></span>
                        </a>
                    </li>
                    <li class="sub-menu" style={boolModalites == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/modalite')} style={boolModalites == true ? { color: 'white' } : {}}>
                            <i class="fa fa-clipboard"></i>
                            <span>Modalités</span>
                        </a>
                    </li>
                    <li class="sub-menu" style={boolExamens == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/examens')} style={boolExamens == true ? { color: 'white' } : {}}>
                            <i class="fa fa-clipboard"></i>
                            <span>Examens</span>
                        </a>
                    </li>

                    <li class="sub-menu" style={boolGains == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/gains')} style={boolGains == true ? { color: 'white' } : {}}>
                            <i class="fa fa-money"></i>
                            <span>Gains</span>
                        </a>
                    </li>
                    <li class="sub-menu" style={boolContrat == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/contrats')} style={boolContrat == true ? { color: 'white' } : {}}>
                            <i class="fa fa-file"></i>
                            <span>Contrats</span>
                        </a>
                    </li>
                    <li class="sub-menu" style={boolManipulateur == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/manipulateurs')} style={boolManipulateur == true ? { color: 'white' } : {}}>
                            <i class="fa fa-users"></i>
                            <span>Manipulateurs</span>
                        </a>
                    </li>
                    <li class="sub-menu" style={boolNotification == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/notifications')} style={boolNotification == true ? { color: 'white' } : {}}>
                            <i class="fa fa-bell-o"></i>
                            <span>Notifications</span>
                        </a>
                    </li>


                    <li class="sub-menu" style={boolMcr == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/modelcompterendu')} style={boolMcr == true ? { color: 'white' } : {}}>
                            <i class="fa fa-clipboard"></i>
                            <span>Models compte rendu</span>
                        </a>
                    </li>
                    <li class="sub-menu" style={boolStatistiques == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/statistiques')} style={boolStatistiques == true ? { color: 'white' } : {}}>
                            <i class="fa fa-bar-chart-o"></i>
                            <span>Statistiques</span>
                        </a>
                    </li>
                    <li class="sub-menu" style={boolAbonnement == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/abonnement')} style={boolAbonnement == true ? { color: 'white' } : {}}>
                            <i class="fa fa-money"></i>
                            <span>Abonnements</span>
                        </a>
                    </li>
                    <li class="sub-menu" style={boolAgenda == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/agenda')} style={boolAgenda == true ? { color: 'white' } : {}}>
                            <i class="fa fa-book"></i>
                            <span>Agenda</span>
                        </a>
                    </li>

                    <li className="sub-menu" style={boolCorbeille == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
                        <a href="javascript:;" onClick={() => navigate('/corbeille')} style={boolCorbeille == true ? { color: 'white' } : {}}>
                            <i class="fa fa-bitbucket"></i>
                            <span>Corbeille&nbsp;&nbsp;<span class="badge " style={{ backgroundColor: 'red' }}>{dossiersCorbeille.length}</span></span>
                        </a>
                    </li>
                </ul>
            </div>
        </aside>
    );
}

export default AsideBar;